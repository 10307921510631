



























import { Vue, Provide, Component } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from "@/serve/mine";
@Component({
  components: {
    Nav,
  },
})
export default class Message extends Vue {
  moveMessage = 0;
  sysMessage = 0;
  @Provide() num = 0;
  @Provide() num2 = 0;
  created(){
    this.getNoRead()
    setInterval(() => { 
    this.getNoRead()
    }, 1000*60*5)
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
  getNoRead() {
    serve.getNoRead().then((res) => {
      this.moveMessage = res.data.data.moveMessage;
      this.sysMessage = res.data.data.sysMessage;
    });
  }
}
